body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Poppins",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-announcement-wrapper {
  overflow-y: scroll;
}

.grow-font-size{
  font-size: 16px;
  font-family: "Poppins", sans-serif !important; 
}
#print-content{
  display: none;
}

@page {
  margin: 14mm 17mm 17mm 17mm;
}

@media print {

  html, body {
    /*a4 standard*/
    /*width: 21cm;*/
    width: 1024px;
    overflow: visible !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .no-print {
    display: none !important;
  }

  #print-content{
    display: block;
  }

  .pagebreak{
    clear: both;
    page-break-before: always;
  }

  .pagebreakavoid{
    break-inside: avoid;
  }

   /*MODAL SECTION*/

  .modal-wrapper {
    position: initial !important;
    width: 100%;
    overflow: visible !important;
  }

  /* On modal is open (like inbox detail) this style will be apply */
  body[style*="hidden"] #root {
    display: none !important;
  }

  .modal-wrapper>div {
    position: initial !important;
    transform: none !important;;
    top: 0 !important;;
    left: 0 !important;;
    max-width: 100% !important;;
    border-radius: 0 !important;
  }

  .modal-body {
    padding: 0 !important;
    position: initial !important;
  }

  /*END MODAL SECTION*/

  /* Move to MUI styles... */
  .MuiCircularProgress-root.top svg circle, .MuiCircularProgress-root.top-circle svg circle {
    stroke: #ccc !important;
  }

  .MuiCircularProgress-root.bottom svg circle, .MuiCircularProgress-root.bottom-circle svg circle {
    stroke: #454545 !important;
  }

  .MuiLinearProgress-root{
    background-color: #ccc !important;

  }

  .MuiLinearProgress-bar {
    background-color: #454545 !important;
  }

}

.container-filters > div > div:not(.SingleDatePicker) {
    box-shadow: 2px 6px 8px rgba(98, 102, 113, 0.06), 0 2px 2px rgba(98, 102, 113, 0.06);
    border-radius: 6px;
    padding: 3px 0;
}